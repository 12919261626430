import { createSvgIcon } from '@mui/material/utils';

const Job = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
  >
    <path
      fillRule="evenodd"
      d="M20.33,7.68c0.32-0.08,0.56-0.35,0.56-0.69V4.31c0-0.4-0.32-0.73-0.73-0.73h-4.19h-2.82H8.72H7.19H3.7  c-0.4,0-0.73,0.32-0.73,0.73v2.68c0,0.4,0.32,0.73,0.73,0.73h0.41l2.43,12.88c0.06,0.34,0.36,0.59,0.71,0.59h9.37  c0.35,0,0.65-0.25,0.71-0.59l0.65-3.43c0.8,0.27,1.55,0.43,2.22,0.43c0.94,0,1.74-0.28,2.32-0.86  C24.26,15.01,23.29,11.32,20.33,7.68z M4.43,5.33v-0.3h2.71H8.6h6.91h2.29h1.65v0.3v0.93h-0.37h-2.07H8.92H7.39H4.43V5.33z   M16.02,19.73H7.85L5.59,7.71h2.35h1.62h8.73l-1.39,7.38c-1.02-0.55-2.06-1.28-3.07-2.16c0.06-0.17,0.1-0.36,0.1-0.55  c0-0.96-0.78-1.73-1.73-1.73c-0.19,0-0.37,0.04-0.54,0.1c-0.48,0.16-0.87,0.52-1.06,0.99c-0.08,0.2-0.13,0.42-0.13,0.65  c0,0.96,0.78,1.73,1.73,1.73c0.22,0,0.43-0.05,0.63-0.12c1.27,1.12,2.56,1.98,3.79,2.59L16.02,19.73z M12.48,12.38  c0,0.15-0.13,0.28-0.28,0.28c-0.15,0-0.28-0.13-0.28-0.28c0-0.15,0.13-0.28,0.28-0.28C12.35,12.1,12.48,12.23,12.48,12.38z   M21.51,15.71c-0.57,0.57-1.75,0.57-3.25,0L19.52,9C21.8,11.99,22.47,14.75,21.51,15.71z"
      clipRule="evenodd"
    />
  </svg>,
  'Job'
);

export default Job;
