import { createSvgIcon } from '@mui/material/utils';

const Invoice = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
  >
    <path
      fillRule="evenodd"
      d="M12,22.3c-1.41,0-2.75-0.27-4-0.81c-1.24-0.54-2.34-1.28-3.27-2.21S3.06,17.25,2.51,16c-0.54-1.25-0.82-2.6-0.82-4.01    c0-1.41,0.28-2.76,0.82-4C3.06,6.75,3.8,5.65,4.73,4.72S6.76,3.05,8,2.51c1.24-0.54,2.59-0.81,4-0.81s2.75,0.27,4,0.81    c1.24,0.54,2.34,1.28,3.27,2.21s1.67,2.03,2.22,3.27c0.54,1.24,0.82,2.59,0.82,4c0,1.41-0.28,2.76-0.82,4.01    c-0.54,1.25-1.29,2.35-2.22,3.28s-2.03,1.67-3.27,2.21C14.75,22.02,13.41,22.3,12,22.3z M12,3.29c-1.2,0-2.35,0.23-3.4,0.68    C7.55,4.42,6.62,5.04,5.84,5.83C5.06,6.61,4.43,7.54,3.98,8.59c-0.45,1.05-0.68,2.2-0.68,3.4s0.23,2.35,0.68,3.4    c0.45,1.05,1.08,1.98,1.86,2.77c0.78,0.78,1.71,1.41,2.77,1.86c1.05,0.45,2.19,0.68,3.4,0.68s2.35-0.23,3.4-0.68    c1.05-0.45,1.98-1.08,2.77-1.86c0.78-0.78,1.41-1.71,1.86-2.77c0.45-1.05,0.68-2.2,0.68-3.4s-0.23-2.35-0.68-3.4    c-0.45-1.05-1.08-1.98-1.86-2.77c-0.78-0.78-1.71-1.41-2.77-1.86C14.35,3.52,13.2,3.29,12,3.29z"
    />
    <path
      d="M12.07,18.54c-0.19,0-0.37-0.06-0.52-0.18c-0.16-0.13-0.24-0.32-0.24-0.57v-0.48c-0.29-0.05-0.56-0.13-0.81-0.25    c-0.31-0.15-0.59-0.34-0.82-0.59c-0.23-0.24-0.42-0.53-0.56-0.86c-0.14-0.33-0.2-0.7-0.2-1.11c0-0.31,0.14-0.48,0.25-0.57    c0.15-0.11,0.32-0.17,0.5-0.17c0.18,0,0.35,0.06,0.5,0.17c0.17,0.13,0.25,0.32,0.25,0.58c0,0.23,0.04,0.43,0.12,0.59    c0.08,0.17,0.19,0.31,0.34,0.42c0.14,0.11,0.32,0.2,0.51,0.26c0.2,0.06,0.4,0.09,0.62,0.09h0.14c0.52,0,0.89-0.12,1.11-0.35    c0.22-0.24,0.33-0.59,0.33-1.06c0-0.27-0.04-0.49-0.12-0.66c-0.08-0.17-0.2-0.33-0.34-0.46c-0.15-0.14-0.32-0.26-0.51-0.36    l-0.63-0.35c-0.36-0.2-0.7-0.38-1.03-0.53c-0.34-0.17-0.66-0.36-0.94-0.58c-0.29-0.23-0.52-0.51-0.69-0.82    c-0.18-0.32-0.26-0.74-0.26-1.23c0-0.33,0.06-0.65,0.18-0.95c0.12-0.3,0.29-0.57,0.51-0.8c0.22-0.23,0.48-0.43,0.77-0.58    c0.24-0.12,0.51-0.22,0.78-0.27V6.21c0-0.25,0.08-0.44,0.24-0.57c0.15-0.12,0.32-0.18,0.5-0.18c0.18,0,0.35,0.06,0.5,0.18    c0.12,0.09,0.25,0.26,0.25,0.57v0.65c0.26,0.06,0.5,0.14,0.73,0.26c0.28,0.14,0.53,0.32,0.74,0.54c0.21,0.22,0.38,0.47,0.51,0.75    c0.13,0.28,0.19,0.58,0.19,0.9c0,0.25-0.08,0.44-0.23,0.56c-0.14,0.12-0.31,0.18-0.49,0.18c-0.18,0-0.35-0.06-0.5-0.17    c-0.16-0.12-0.26-0.3-0.28-0.53c-0.03-0.31-0.17-0.55-0.41-0.75s-0.57-0.3-0.97-0.3h-0.13c-0.27,0-0.5,0.04-0.68,0.13    c-0.18,0.09-0.33,0.19-0.44,0.31c-0.11,0.12-0.18,0.24-0.23,0.38c-0.05,0.14-0.07,0.26-0.07,0.36c0,0.23,0.04,0.42,0.13,0.57    c0.09,0.16,0.2,0.3,0.35,0.41c0.15,0.12,0.32,0.23,0.51,0.32c0.21,0.1,0.41,0.21,0.6,0.32c0.36,0.19,0.71,0.38,1.05,0.55    c0.36,0.18,0.68,0.4,0.95,0.64c0.28,0.25,0.51,0.55,0.68,0.9c0.17,0.35,0.26,0.79,0.26,1.31c0,0.8-0.21,1.46-0.64,1.96    c-0.39,0.47-0.94,0.76-1.63,0.87l-0.01,0.48c-0.01,0.25-0.1,0.43-0.25,0.56C12.42,18.48,12.25,18.54,12.07,18.54z"
      clipRule="evenodd"
    />
  </svg>,
  'Invoice'
);

export default Invoice;
