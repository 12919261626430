import { createSvgIcon } from '@mui/material/utils';

const Product = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
  >
    <path
      fillRule="evenodd"
      d="M20.87,6.86c0-0.01,0-0.01-0.01-0.02c-0.01-0.02-0.04-0.04-0.05-0.06c-0.22-0.35-0.52-0.66-0.9-0.88l-6.59-3.77  c-0.81-0.47-1.82-0.47-2.63,0l-6.6,3.77C3.71,6.13,3.41,6.44,3.18,6.8C3.17,6.82,3.15,6.83,3.14,6.85c0,0,0,0.01,0,0.01  C2.9,7.26,2.77,7.72,2.77,8.19v7.54c0,0.94,0.5,1.81,1.32,2.28l6.59,3.77c0.41,0.23,0.86,0.35,1.32,0.35c0,0,0,0,0,0c0,0,0,0,0,0  c0,0,0,0,0,0c0.46,0,0.91-0.12,1.31-0.35l6.6-3.77c0.81-0.47,1.32-1.34,1.32-2.28V8.2C21.23,7.72,21.1,7.26,20.87,6.86z M11.43,3.44  c0.17-0.1,0.37-0.15,0.57-0.15c0.2,0,0.39,0.05,0.57,0.15l6.38,3.64l-2.7,1.56L9.35,4.63L11.43,3.44z M12,11.11L5.05,7.09l2.8-1.6  l6.9,4.02L12,11.11z M4.84,16.71c-0.35-0.2-0.57-0.58-0.57-0.98V8.37l6.98,4.04v7.97L4.84,16.71z M19.17,16.71l-6.42,3.67V12.4  l6.98-4.04v7.36C19.73,16.13,19.51,16.51,19.17,16.71z"
      clipRule="evenodd"
    />
  </svg>,
  'Product'
);

export default Product;
