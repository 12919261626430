import { createSvgIcon } from '@mui/material/utils';

const Dosages = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
  >
    <path
      fillRule="evenodd"
      d="M21.4,9.66l-0.32-0.32l-1.06-1.06l-3.79-3.79l-1.05-1.05l-0.33-0.33c-0.29-0.29-0.77-0.29-1.06,0   c-0.29,0.29-0.29,0.77,0,1.06l0.32,0.32l-2.48,2.47l-1.06,1.06L9.52,9.1l-1.06,1.06L7.4,11.22l-1.06,1.06l-1.7,1.7   C3,15.6,3,18.25,4.63,19.88c1.63,1.63,4.28,1.63,5.91,0l9.48-9.48l0.32,0.32c0.29,0.29,0.77,0.29,1.06,0   C21.69,10.43,21.69,9.95,21.4,9.66z M9.48,18.82c-1.04,1.04-2.74,1.04-3.79,0c-1.04-1.04-1.04-2.74,0-3.79l1.7-1.7l0.92,0.92   c0.29,0.29,0.77,0.29,1.06,0s0.29-0.77,0-1.06l-0.92-0.92l1.06-1.06l0.92,0.92c0.29,0.29,0.77,0.29,1.06,0   c0.29-0.29,0.29-0.77,0-1.06l-0.92-0.92l1.06-1.06l0.92,0.92c0.29,0.29,0.77,0.29,1.06,0c0.29-0.29,0.29-0.77,0-1.06l-0.93-0.93   l2.47-2.48l3.79,3.79L9.48,18.82z"
      clipRule="evenodd"
    />
  </svg>,
  'Dosages'
);

export default Dosages;
