import { createSvgIcon } from '@mui/material/utils';

const Account = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
  >
    <path
      fillRule="evenodd"
      d="M11.99,21.14c-1.13,0-2.06-0.74-2.33-1.83c-0.06-0.25-0.23-0.45-0.47-0.55c-0.24-0.1-0.5-0.08-0.72,0.06    c-0.96,0.59-2.15,0.45-2.94-0.35c-0.8-0.8-0.94-1.98-0.35-2.94c0.13-0.22,0.15-0.48,0.06-0.72c-0.1-0.24-0.3-0.41-0.55-0.47    c-1.1-0.27-1.83-1.2-1.83-2.33s0.74-2.06,1.83-2.33C4.94,9.61,5.14,9.44,5.23,9.2c0.1-0.24,0.08-0.5-0.06-0.72    C4.59,7.52,4.73,6.34,5.53,5.54c0.8-0.8,1.98-0.94,2.94-0.35c0.22,0.13,0.48,0.15,0.72,0.06c0.24-0.1,0.41-0.3,0.47-0.55l0,0    c0.27-1.1,1.2-1.83,2.33-1.83c1.13,0,2.06,0.74,2.33,1.83c0.06,0.25,0.23,0.45,0.47,0.55c0.24,0.1,0.5,0.08,0.72-0.06    c0.96-0.59,2.15-0.44,2.94,0.35c0.8,0.8,0.94,1.98,0.35,2.94c-0.13,0.22-0.15,0.48-0.06,0.72c0.1,0.24,0.3,0.41,0.55,0.47    c1.1,0.27,1.83,1.2,1.83,2.33s-0.74,2.06-1.83,2.33c-0.25,0.06-0.45,0.23-0.55,0.47c-0.1,0.24-0.08,0.5,0.06,0.72    c0.59,0.96,0.45,2.15-0.35,2.94c-0.8,0.8-1.98,0.94-2.94,0.35c-0.22-0.13-0.48-0.16-0.72-0.06c-0.24,0.1-0.41,0.3-0.47,0.55    C14.06,20.4,13.12,21.14,11.99,21.14z M8.88,17.09c0.31,0,0.62,0.06,0.92,0.19c0.71,0.3,1.23,0.9,1.41,1.65    c0.14,0.56,0.63,0.61,0.77,0.61c0.15,0,0.64-0.04,0.77-0.61c0.18-0.75,0.7-1.35,1.41-1.65c0.71-0.29,1.5-0.23,2.16,0.17    c0.5,0.3,0.87-0.01,0.98-0.12c0.1-0.1,0.42-0.48,0.12-0.98c-0.4-0.66-0.46-1.45-0.17-2.16c0.3-0.71,0.9-1.23,1.65-1.41    c0.56-0.14,0.61-0.63,0.61-0.77s-0.04-0.64-0.61-0.77c-0.75-0.18-1.35-0.7-1.65-1.41c-0.3-0.71-0.24-1.5,0.17-2.17    c0.3-0.5-0.01-0.87-0.12-0.98s-0.48-0.42-0.98-0.12c-0.66,0.4-1.45,0.46-2.16,0.17c-0.71-0.3-1.23-0.9-1.41-1.65    c-0.14-0.57-0.63-0.61-0.77-0.61c-0.15,0-0.64,0.04-0.77,0.61l0,0c-0.18,0.75-0.7,1.35-1.41,1.65S8.3,6.96,7.64,6.55    c-0.49-0.3-0.87,0.01-0.98,0.12c-0.1,0.1-0.42,0.48-0.12,0.98c0.4,0.66,0.46,1.45,0.17,2.16c-0.3,0.71-0.9,1.23-1.65,1.41    C4.5,11.36,4.45,11.85,4.45,12s0.04,0.64,0.61,0.77c0.75,0.18,1.35,0.7,1.65,1.41c0.3,0.71,0.24,1.5-0.17,2.17    c-0.3,0.5,0.01,0.87,0.12,0.98c0.1,0.1,0.48,0.42,0.98,0.12C8.03,17.21,8.45,17.09,8.88,17.09z"
    />
    <path
      d="M11.99,15.58c-1.97,0-3.58-1.61-3.58-3.58s1.61-3.58,3.58-3.58c1.97,0,3.58,1.61,3.58,3.58S13.97,15.58,11.99,15.58z     M11.99,10.02c-1.09,0-1.98,0.89-1.98,1.98s0.89,1.98,1.98,1.98s1.98-0.89,1.98-1.98S13.08,10.02,11.99,10.02z"
      clipRule="evenodd"
    />
  </svg>,
  'Account'
);

export default Account;
