import type { FC } from 'react';
import { useCallback } from 'react';
import { useRouter } from 'next/navigation';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import {
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Popover,
  Typography,
  Tooltip,
} from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import { useMemo } from 'react';
import { getAcronym } from 'src/utils/string';
import { useLogoutMutation } from 'src/api/auth';
import getConfig from 'next/config';
import { useTranslation } from 'react-i18next';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import EditIcon from '@mui/icons-material/Edit';
import { paths } from 'src/paths';
import { useSelector } from 'src/store';
import { useAuth } from 'src/hooks/use-auth';

interface AccountPopoverProps {
  anchorEl: null | Element;
  onClose?: () => void;
  open?: boolean;
}

export const AccountPopover: FC<AccountPopoverProps> = (props) => {
  const { anchorEl, onClose, open, ...other } = props;
  const { organisation } = useSelector((state) => state.account);
  const [logout, { isLoading }] = useLogoutMutation();
  const router = useRouter();
  const { user, isAuthenticated } = useAuth();
  const { publicRuntimeConfig } = getConfig();
  const { t } = useTranslation();

  const handleLogout = useCallback(async (): Promise<void> => {
    try {
      onClose?.();
      await logout({}).unwrap();
      router.push(paths.authentication.login);
    } catch (err) {
      console.error(err);
      toast.error('Unable to logout.');
    }
  }, [router, onClose]);

  const handleEdit = useCallback(
    () => router.push(paths.setup.team.editUser.replace(':id', user.id.toString())),
    [user]
  );

  const userAcronym = useMemo<string>(() => {
    if (user) return getAcronym(`${user.first_name} ${user.last_name}`);

    return null;
  }, [user]);

  const licenseInfo = useMemo(
    () => (
      <Typography variant="caption">
        {`${t('Pooltrackr Limited © Copyright')} ${new Date().getFullYear()}`}{' '}
      </Typography>
    ),
    [publicRuntimeConfig]
  );

  const appVersion = useMemo<string>(() => {
    if (publicRuntimeConfig?.version) return `${t('Version')} ${publicRuntimeConfig?.version}`;

    return null;
  }, [publicRuntimeConfig, t]);

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'bottom',
      }}
      keepMounted
      onClose={onClose}
      open={open}
      PaperProps={{ sx: { width: 350 } }}
      transitionDuration={0}
      {...other}
    >
      <List disablePadding>
        {isAuthenticated && (
          <>
            <ListItem divider>
              <ListItemText
                primary={<Typography variant="subtitle2">{organisation.name}</Typography>}
              />
            </ListItem>
            <ListItem
              divider
              sx={{
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: 'action.hover',
                },
              }}
              onClick={handleEdit}
            >
              <ListItemAvatar>
                <Avatar
                  sx={{
                    height: 32,
                    width: 32,
                  }}
                >
                  {userAcronym}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={<Typography variant="subtitle2">{user.full_name}</Typography>}
                secondary={
                  <Typography
                    color="text.secondary"
                    variant="body2"
                  >
                    {user.email}
                  </Typography>
                }
              />
              <ListItemSecondaryAction>
                <Tooltip title={t('Edit profile')}>
                  <IconButton
                    edge="end"
                    color="primary"
                    onClick={handleEdit}
                  >
                    <EditIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </ListItemSecondaryAction>
            </ListItem>
            <ListItemButton
              divider
              disabled={isLoading}
              onClick={handleLogout}
            >
              <ListItemIcon>
                <LogoutIcon fontFamily="small" />
              </ListItemIcon>
              <ListItemText primary={<Typography variant="body1">{t('Logout')}</Typography>} />
            </ListItemButton>
          </>
        )}
        <ListItem>
          <ListItemIcon>
            <PrivacyTipIcon fontFamily="small" />
          </ListItemIcon>
          <ListItemText
            disableTypography
            primary={<Typography variant="subtitle2">{licenseInfo}</Typography>}
            secondary={
              <Typography
                color="text.secondary"
                variant="caption"
              >
                {appVersion}
              </Typography>
            }
          />
        </ListItem>
      </List>
    </Popover>
  );
};

AccountPopover.propTypes = {
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
