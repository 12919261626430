import { createSvgIcon } from '@mui/material/utils';

const Pool = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
  >
    <path
      fillRule="evenodd"
      d="M12.3,20.18c-0.39,0-0.7-0.31-0.7-0.7s0.31-0.7,0.7-0.7c2.11,0,3.83-1.72,3.83-3.83c0-0.39,0.31-0.7,0.7-0.7    s0.7,0.31,0.7,0.7C17.53,17.83,15.18,20.18,12.3,20.18z"
    />
    <path
      d="M12.22,23.2c-1.13,0-2.21-0.22-3.2-0.66C8.02,22.1,7.14,21.5,6.4,20.75C5.66,20,5.06,19.12,4.62,18.12    c-0.44-1-0.68-2.09-0.69-3.23c0-0.91,0.12-1.75,0.35-2.51c0.23-0.75,0.54-1.46,0.93-2.12C5.59,9.62,6.03,9,6.52,8.43    c0.48-0.57,0.97-1.12,1.47-1.65c0.8-0.83,1.54-1.69,2.21-2.54c0.64-0.83,1.09-1.81,1.33-2.92c0.08-0.19,0.16-0.29,0.25-0.38    C11.89,0.85,12,0.8,12.12,0.8c0.14,0,0.28,0.03,0.42,0.1c0.15,0.08,0.27,0.19,0.35,0.34c0.26,1.17,0.71,2.16,1.35,2.99    c0.66,0.85,1.4,1.71,2.21,2.54c0.5,0.54,1,1.08,1.47,1.63c0.49,0.56,0.93,1.17,1.31,1.82c0.39,0.65,0.7,1.37,0.93,2.13    c0.23,0.77,0.35,1.62,0.35,2.54c0,1.15-0.22,2.24-0.66,3.24c-0.43,1-1.03,1.88-1.77,2.63c-0.74,0.75-1.63,1.35-2.64,1.79    C14.43,22.98,13.34,23.2,12.22,23.2z M12.22,4.04c-0.35,0.63-0.75,1.23-1.18,1.81C10.49,6.56,9.88,7.25,9.22,7.9    C8.73,8.42,8.25,8.94,7.8,9.46c-0.44,0.5-0.84,1.04-1.18,1.59c-0.34,0.55-0.61,1.14-0.81,1.76c-0.2,0.62-0.3,1.32-0.3,2.08    c0,0.94,0.18,1.83,0.53,2.63c0.35,0.81,0.83,1.53,1.44,2.13c0.6,0.6,1.32,1.08,2.13,1.42c0.81,0.34,1.69,0.52,2.61,0.52    s1.8-0.17,2.61-0.52c0.81-0.34,1.53-0.82,2.13-1.42c0.6-0.6,1.09-1.32,1.44-2.13c0.35-0.81,0.53-1.7,0.53-2.63    c0-0.76-0.1-1.46-0.3-2.08c-0.2-0.62-0.48-1.22-0.81-1.76c-0.34-0.55-0.74-1.09-1.18-1.59c-0.45-0.51-0.92-1.03-1.42-1.56    c-0.65-0.59-1.27-1.27-1.82-2.02C12.96,5.28,12.56,4.67,12.22,4.04z"
      clipRule="evenodd"
    />
  </svg>,
  'Pool'
);

export default Pool;
