import type { FC, ReactNode } from 'react';
import NextLink from 'next/link';
import PropTypes from 'prop-types';
import ChevronRightIcon from '@untitled-ui/icons-react/build/esm/ChevronRight';
import { Box, ButtonBase, ListItemButton, ListItemText, SvgIcon } from '@mui/material';
import {
  Dropdown,
  HorizontalDropdownMenu as DropdownMenu,
  DropdownTrigger,
} from '@/components/dropdown';

interface Item {
  disabled?: boolean;
  icon?: ReactNode;
  items?: Item[];
  label?: ReactNode;
  path?: string;
  title: string;
}

interface SideNavItemCompactProps {
  active?: boolean;
  disabled?: boolean;
  icon?: ReactNode;
  items?: Item[];
  label?: ReactNode;
  path?: string;
  title: string;
}

const renderChildItems = ({ items, depth = 0 }: { items: Item[]; depth?: number }) => {
  return items.map((item) => {
    const { disabled, items, path, title } = item;

    // Branch
    if (items) {
      return (
        <Dropdown key={title}>
          <DropdownTrigger>
            <ListItemButton
              disabled={disabled}
              sx={{
                borderRadius: 1,
                px: 1.5,
                py: 0.5,
              }}
            >
              <ListItemText
                primary={item.title}
                primaryTypographyProps={{
                  sx: {
                    color: 'text.secondary',
                    fontSize: 14,
                    fontWeight: 500,
                  },
                }}
              />
              <SvgIcon
                fontSize="small"
                sx={{ color: 'neutral.400' }}
              >
                <ChevronRightIcon />
              </SvgIcon>
            </ListItemButton>
          </DropdownTrigger>
          <DropdownMenu
            anchorOrigin={{
              horizontal: 'right',
              vertical: 'top',
            }}
            disableScrollLock
            PaperProps={{
              elevation: 8,
              sx: {
                maxWidth: '100%',
                ml: 1,
                p: 1,
                width: 200,
              },
            }}
            transformOrigin={{
              horizontal: 'left',
              vertical: 'top',
            }}
          >
            {renderChildItems({ items, depth: depth + 1 })}
          </DropdownMenu>
        </Dropdown>
      );
    }

    let linkProps: any = undefined;

    if (path) {
      const isExternal = path.startsWith('http');

      linkProps = isExternal
        ? {
            component: 'a',
            href: path,
            target: '_blank',
          }
        : {
            component: NextLink,
            href: path,
          };
    }

    // Leaf
    return (
      <ListItemButton
        disabled={disabled}
        key={title}
        sx={{
          borderRadius: 1,
          px: 1.5,
          py: 0.5,
        }}
        {...linkProps}
      >
        <ListItemText
          primary={item.title}
          primaryTypographyProps={{
            sx: {
              color: 'text.secondary',
              fontSize: 14,
              fontWeight: 500,
            },
          }}
        />
      </ListItemButton>
    );
  });
};

export const SideNavItemCompact: FC<SideNavItemCompactProps> = (props) => {
  const { active, disabled, items, icon, path } = props;

  // With dropdown

  if (items) {
    let linkProps: any = undefined;
    const firstItem = items[0];

    if (firstItem?.path) {
      const isExternal = firstItem.path.startsWith('http');

      linkProps = isExternal
        ? {
            component: 'a',
            href: firstItem.path,
            target: '_blank',
          }
        : {
            component: NextLink,
            href: firstItem.path,
          };
    }

    return (
      <Dropdown>
        <DropdownTrigger>
          <li>
            <ButtonBase
              disabled={disabled}
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
                py: 1,
                textAlign: 'center',
                width: '100%',
                ...(active && {
                  backgroundColor: 'var(--nav-item-active-bg)',
                }),
                '&:hover': {
                  backgroundColor: 'var(--nav-item-hover-bg)',
                },
              }}
              {...linkProps}
            >
              <Box
                component="span"
                sx={{
                  alignItems: 'center',
                  color: 'var(--nav-item-icon-color)',
                  display: 'inline-flex',
                  justifyContent: 'center',
                  ...(active && {
                    color: 'var(--nav-item-icon-active-color)',
                  }),
                }}
              >
                {icon}
              </Box>
            </ButtonBase>
          </li>
        </DropdownTrigger>
        <DropdownMenu
          disableScrollLock
          PaperProps={{
            elevation: 8,
            sx: {
              maxWidth: '100%',
              p: 1,
              width: 200,
            },
          }}
        >
          {renderChildItems({ items, depth: 0 })}
        </DropdownMenu>
      </Dropdown>
    );
  }

  // Without dropdown

  let linkProps: any = undefined;

  if (path) {
    const isExternal = path.startsWith('http');

    linkProps = isExternal
      ? {
          component: 'a',
          href: path,
          target: '_blank',
        }
      : {
          component: NextLink,
          href: path,
        };
  }

  return (
    <li>
      <ButtonBase
        disabled={disabled}
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          py: 1,
          textAlign: 'center',
          width: '100%',
          ...(active && {
            backgroundColor: 'var(--nav-item-active-bg)',
          }),
          '&:hover': {
            backgroundColor: 'var(--nav-item-hover-bg)',
          },
        }}
        {...linkProps}
      >
        {icon && (
          <Box
            component="span"
            sx={{
              alignItems: 'center',
              color: 'var(--nav-item-icon-color)',
              display: 'inline-flex',
              justifyContent: 'center',
              ...(active && {
                color: 'var(--nav-item-icon-active-color)',
              }),
            }}
          >
            {icon}
          </Box>
        )}
      </ButtonBase>
    </li>
  );
};

SideNavItemCompact.propTypes = {
  active: PropTypes.bool,
  icon: PropTypes.node,
  items: PropTypes.array,
  label: PropTypes.node,
  path: PropTypes.string,
  title: PropTypes.string.isRequired,
};
