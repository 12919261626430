import { createSvgIcon } from '@mui/material/utils';

const Tools = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
  >
    <path
      fillRule="evenodd"
      d="M9.59,3.9C8.92,3.58,8.13,3.66,7.54,4.12C5.36,5.82,4.34,8.6,4.9,11.31c0.38,1.87,1.49,3.51,3.08,4.55v3.62  c0,0.45,0.36,0.81,0.81,0.81h0c0.44,0,0.8-0.36,0.8-0.8V15.3c0-0.22-0.12-0.42-0.3-0.53l0,0c-1-0.58-1.86-1.41-2.36-2.46  C5.75,9.84,6.49,7,8.55,5.39c0.1-0.08,0.24-0.1,0.35-0.04C9,5.4,9.07,5.5,9.06,5.62v3.14c0,1.04,0.84,1.88,1.88,1.88h2.15  c1.04,0,1.88-0.84,1.88-1.88V5.61c-0.01-0.11,0.05-0.22,0.16-0.27c0.12-0.06,0.25-0.04,0.35,0.04c2.01,1.56,2.8,4.35,1.62,6.87  c-0.51,1.09-1.39,1.95-2.44,2.54l0,0c-0.14,0.08-0.23,0.23-0.23,0.4v4.3c0,0.45,0.36,0.81,0.81,0.81h0c0.44,0,0.8-0.36,0.8-0.8  v-3.65c2.01-1.34,3.21-3.6,3.22-6.01c0.01-2.23-1.03-4.34-2.79-5.71c-0.58-0.46-1.37-0.54-2.04-0.22c-0.66,0.31-1.08,0.98-1.07,1.7  v3.15c0,0.15-0.12,0.27-0.27,0.27h-2.15c-0.15,0-0.27-0.12-0.27-0.27V5.61C10.67,4.88,10.25,4.22,9.59,3.9z"
      clipRule="evenodd"
    />
  </svg>,
  'Tools'
);

export default Tools;
