import type { FC, ReactNode } from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { HorizontalLayout } from './horizontal-layout';
import { VerticalLayout } from './vertical-layout';
import type { Section } from './config';
import { getSections } from './config';
import { useSettings } from 'src/hooks/use-settings';
import { AuthGuard } from '@/components/authentication/auth-guard';
import { useAuth } from 'src/hooks/use-auth';
import { filterAvailableNavSections } from 'src/utils/permission';

const useFilteredTranslatedSections = (): Section[] => {
  const { t } = useTranslation();
  const { user, subscriptionPlan } = useAuth();

  return useMemo(() => {
    const allSections = getSections(t);

    return !user || !subscriptionPlan
      ? []
      : filterAvailableNavSections(allSections, user, subscriptionPlan);
  }, [t, user, subscriptionPlan]);
};

interface LayoutProps {
  children?: ReactNode;
  permission?: string | null;
  subscriptions?: string[] | null;
}

export const Layout: FC<LayoutProps> = ({ permission, subscriptions, ...props }) => {
  const { settings } = useSettings();
  const sections = useFilteredTranslatedSections();

  if (settings.layout === 'horizontal') {
    return (
      <HorizontalLayout
        sections={sections}
        navColor={settings.navColor}
        {...props}
      />
    );
  }

  return (
    <AuthGuard
      permission={permission}
      subscriptions={subscriptions}
    >
      <VerticalLayout
        sections={sections}
        navColor={settings.navColor}
        {...props}
      />
    </AuthGuard>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
};
