import { createSvgIcon } from '@mui/material/utils';

const Contact = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
  >
    <path
      fillRule="evenodd"
      d="M15.45,13.39c1.81-1.15,3.01-3.16,3.01-5.45c0-3.56-2.9-6.46-6.46-6.46c-3.56,0-6.46,2.9-6.46,6.46   c0,2.29,1.2,4.31,3.01,5.45c-3.24,1.35-5.52,4.56-5.52,8.28c0,0.47,0.38,0.85,0.85,0.85s0.85-0.38,0.85-0.85   c0-4.01,3.26-7.27,7.27-7.27s7.27,3.26,7.27,7.27c0,0.47,0.38,0.85,0.85,0.85s0.85-0.38,0.85-0.85   C20.97,17.95,18.69,14.75,15.45,13.39z M7.24,7.94c0-2.63,2.14-4.76,4.76-4.76c2.63,0,4.76,2.14,4.76,4.76S14.63,12.7,12,12.7   C9.37,12.7,7.24,10.56,7.24,7.94z"
      clipRule="evenodd"
    />
  </svg>,
  'Contact'
);

export default Contact;
