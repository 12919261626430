import { FC, useCallback } from 'react';
import { useMemo } from 'react';
import { usePathname } from 'next/navigation';
import NextLink from 'next/link';
import PropTypes from 'prop-types';
import { CSSObject, Box, Collapse, Drawer, Stack, styled, useTheme } from '@mui/material';
import type { Section } from '../config';
import type { Theme } from '@mui/material';
import { SideNavSection } from './side-nav-section';
import { NavColor } from 'src/types/settings';
import Logo from '@/components/Logo';
import { paths } from 'src/paths';
import { TOP_NAV_HEIGHT } from './top-nav';
import { SideNavCompactSection } from './side-nav-compact-section';
import { SIDE_NAV_COMPACT_WIDTH, SIDE_NAV_WIDTH } from '.';

const useCssVars = (color: NavColor): Record<string, string> => {
  const theme = useTheme();

  return useMemo((): Record<string, string> => {
    switch (color) {
      case 'blend-in':
        if (theme.palette.mode === 'dark') {
          return {
            '--nav-bg': theme.palette.background.default,
            '--nav-color': theme.palette.neutral[100],
            '--nav-border-color': theme.palette.neutral[700],
            '--nav-logo-border': theme.palette.neutral[700],
            '--nav-section-title-color': theme.palette.neutral[400],
            '--nav-item-color': theme.palette.neutral[400],
            '--nav-item-hover-bg': 'rgba(255, 255, 255, 0.04)',
            '--nav-item-active-bg': 'rgba(255, 255, 255, 0.04)',
            '--nav-item-active-color': theme.palette.primary.main,
            '--nav-item-disabled-color': theme.palette.neutral[600],
            '--nav-item-icon-color': theme.palette.neutral[500],
            '--nav-item-icon-active-color': theme.palette.primary.main,
            '--nav-item-icon-disabled-color': theme.palette.neutral[700],
            '--nav-item-chevron-color': theme.palette.neutral[700],
            '--nav-scrollbar-color': theme.palette.neutral[400],
          };
        } else {
          return {
            '--nav-bg': theme.palette.background.default,
            '--nav-color': theme.palette.text.primary,
            '--nav-border-color': theme.palette.neutral[100],
            '--nav-logo-border': theme.palette.neutral[100],
            '--nav-section-title-color': theme.palette.neutral[900],
            '--nav-item-color': theme.palette.text.secondary,
            '--nav-item-hover-bg': theme.palette.action.hover,
            '--nav-item-active-bg': theme.palette.action.selected,
            '--nav-item-active-color': theme.palette.primary.main,
            '--nav-item-disabled-color': theme.palette.neutral[400],
            '--nav-item-icon-color': theme.palette.neutral[400],
            '--nav-item-icon-active-color': theme.palette.primary.main,
            '--nav-item-icon-disabled-color': theme.palette.neutral[400],
            '--nav-item-chevron-color': theme.palette.neutral[400],
            '--nav-scrollbar-color': theme.palette.neutral[900],
          };
        }

      case 'discreet':
        if (theme.palette.mode === 'dark') {
          return {
            '--nav-bg': theme.palette.neutral[900],
            '--nav-color': theme.palette.neutral[100],
            '--nav-border-color': theme.palette.neutral[700],
            '--nav-logo-border': theme.palette.neutral[700],
            '--nav-section-title-color': theme.palette.neutral[200],
            '--nav-item-color': theme.palette.neutral[400],
            '--nav-item-hover-bg': 'rgba(255, 255, 255, 0.04)',
            '--nav-item-active-bg': 'rgba(255, 255, 255, 0.04)',
            '--nav-item-active-color': theme.palette.primary.main,
            '--nav-item-disabled-color': theme.palette.neutral[600],
            '--nav-item-icon-color': theme.palette.neutral[500],
            '--nav-item-icon-active-color': theme.palette.primary.main,
            '--nav-item-icon-disabled-color': theme.palette.neutral[700],
            '--nav-item-chevron-color': theme.palette.neutral[700],
            '--nav-scrollbar-color': theme.palette.neutral[400],
          };
        } else {
          return {
            '--nav-bg': theme.palette.common.white,
            '--nav-color': theme.palette.text.primary,
            '--nav-border-color': theme.palette.divider,
            '--nav-logo-border': theme.palette.neutral[200],
            '--nav-section-title-color': theme.palette.neutral[900],
            '--nav-item-color': theme.palette.neutral[500],
            '--nav-item-hover-bg': theme.palette.action.hover,
            '--nav-item-active-bg': theme.palette.action.selected,
            '--nav-item-active-color': theme.palette.primary.main,
            '--nav-item-disabled-color': theme.palette.neutral[400],
            '--nav-item-icon-color': theme.palette.neutral[400],
            '--nav-item-icon-active-color': theme.palette.primary.main,
            '--nav-item-icon-disabled-color': theme.palette.neutral[400],
            '--nav-item-chevron-color': theme.palette.neutral[400],
            '--nav-scrollbar-color': theme.palette.neutral[900],
          };
        }

      case 'evident':
        if (theme.palette.mode === 'dark') {
          return {
            '--nav-bg': theme.palette.neutral[800],
            '--nav-color': theme.palette.common.white,
            '--nav-border-color': 'transparent',
            '--nav-logo-border': theme.palette.neutral[700],
            '--nav-section-title-color': theme.palette.neutral[400],
            '--nav-item-color': theme.palette.neutral[400],
            '--nav-item-hover-bg': 'rgba(255, 255, 255, 0.04)',
            '--nav-item-active-bg': 'rgba(255, 255, 255, 0.04)',
            '--nav-item-active-color': theme.palette.common.white,
            '--nav-item-disabled-color': theme.palette.neutral[500],
            '--nav-item-icon-color': theme.palette.neutral[400],
            '--nav-item-icon-active-color': theme.palette.common.white,
            '--nav-item-icon-disabled-color': theme.palette.neutral[500],
            '--nav-item-chevron-color': theme.palette.neutral[600],
            '--nav-scrollbar-color': theme.palette.neutral[400],
          };
        } else {
          return {
            '--nav-bg': theme.palette.primary.main,
            '--nav-color': theme.palette.common.white,
            '--nav-border-color': 'transparent',
            '--nav-logo-border': theme.palette.neutral[700],
            '--nav-section-title-color': theme.palette.neutral[400],
            '--nav-item-color': theme.palette.neutral[400],
            '--nav-item-hover-bg': 'rgba(255, 255, 255, 0.04)',
            '--nav-item-active-bg': 'rgba(255, 255, 255, 0.04)',
            '--nav-item-active-color': theme.palette.common.white,
            '--nav-item-disabled-color': theme.palette.neutral[500],
            '--nav-item-icon-color': theme.palette.neutral[400],
            '--nav-item-icon-active-color': theme.palette.common.white,
            '--nav-item-icon-disabled-color': theme.palette.neutral[500],
            '--nav-item-chevron-color': theme.palette.neutral[600],
            '--nav-scrollbar-color': theme.palette.neutral[400],
          };
        }

      default:
        return {};
    }
  }, [theme, color]);
};

interface SideNavProps {
  onToggleSection: (activeSection: string) => void;
  color?: NavColor;
  activeSection?: string;
  sections?: Section[];
  compact?: boolean;
}

const desktopOpenedMixin = (theme: Theme): CSSObject => ({
  width: SIDE_NAV_WIDTH,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
});

const desktopClosedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: SIDE_NAV_COMPACT_WIDTH,
});

interface SideNavDrawerProps {
  compact: boolean;
}

const SideNavDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== 'compact',
})<SideNavDrawerProps>(({ theme, compact }) => ({
  ...(!compact && {
    ...desktopOpenedMixin(theme),
    '& .MuiDrawer-paper': { ...desktopOpenedMixin(theme), elevation: 16 },
  }),
  ...(compact && {
    ...desktopClosedMixin(theme),
    '& .MuiDrawer-paper': { ...desktopClosedMixin(theme), elevation: 16 },
  }),
}));

export const SideNav: FC<SideNavProps> = (props) => {
  const {
    color = 'discreet',
    activeSection,
    sections = [],
    compact = false,
    onToggleSection,
  } = props;
  const pathname = usePathname();
  const cssVars = useCssVars(color);

  const handleToggleSection = useCallback(
    (_activeSection: string) => {
      onToggleSection?.(_activeSection);
    },
    [onToggleSection]
  );

  return (
    <SideNavDrawer
      anchor="left"
      open
      compact={compact}
      PaperProps={{
        sx: {
          ...cssVars,
          marginTop: `${TOP_NAV_HEIGHT}px`,
          backgroundColor: 'var(--nav-bg)',
          borderRightColor: 'var(--nav-border-color)',
          borderRightStyle: 'solid',
          borderRightWidth: 1,
          color: 'var(--nav-color)',
          overflow: 'hidden',
        },
      }}
      variant="permanent"
    >
      <Collapse in={!compact}>
        <Box
          component={NextLink}
          href={paths.index}
          my={2}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            overflow: 'hidden',
          }}
        >
          <Logo
            sx={{
              height: 50,
              '& img': {
                height: 50,
                objectFit: 'contain',
              },
            }}
          />
        </Box>
      </Collapse>
      <Stack sx={{ height: '100%', pb: 16, overflowY: 'scroll' }}>
        {compact ? (
          <Stack
            component="nav"
            sx={{
              flexGrow: 1,
            }}
          >
            {sections.map((section, index) => (
              <SideNavCompactSection
                items={section.items}
                key={index}
                pathname={pathname}
                subheader={section.subheader}
              />
            ))}
          </Stack>
        ) : (
          <Stack
            component="nav"
            sx={{
              flexGrow: 1,
            }}
          >
            {sections.map((section, index) => (
              <SideNavSection
                items={section.items}
                key={index}
                pathname={pathname}
                compact={compact}
                activeSection={activeSection}
                subheader={section.subheader}
                sectionPath={section.sectionPath}
                onToggleSection={handleToggleSection}
              />
            ))}
          </Stack>
        )}
      </Stack>
    </SideNavDrawer>
  );
};

SideNav.propTypes = {
  color: PropTypes.oneOf(['blend-in', 'discreet', 'evident']),
  sections: PropTypes.array,
};
