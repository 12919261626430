import { createSvgIcon } from '@mui/material/utils';

const Marketing = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
  >
    <path
      fillRule="evenodd"
      d="M10.3,22.47C10.3,22.47,10.3,22.47,10.3,22.47c-1.08,0-2.05-0.68-2.41-1.7l-2.04-5.84c-2.21-1.14-3.2-3.81-2.21-6.14   c0.75-1.78,2.49-2.93,4.42-2.93c0,0,0,0,0,0h1.83c3.7,0,7.14-1.1,8.56-2.73l1.4-1.61V19.8l-1.4-1.61c-1.03-1.18-3.12-2.08-5.6-2.49   v4.2c0,0.68-0.27,1.33-0.75,1.81C11.63,22.2,10.98,22.47,10.3,22.47z M7.73,15.46l1.67,4.78c0.13,0.37,0.5,0.63,0.9,0.63   c0,0,0,0,0,0c0.26,0,0.5-0.1,0.68-0.28c0.18-0.18,0.28-0.42,0.28-0.68v-4.39c-0.45-0.03-0.91-0.05-1.37-0.05H8.06   C7.95,15.47,7.84,15.46,7.73,15.46z M12.86,14.08c2.14,0.31,4.01,0.96,5.4,1.87V5.39c-1.39,0.91-3.26,1.56-5.4,1.87V14.08z    M8.06,7.47c-1.29,0-2.44,0.77-2.95,1.95c-0.69,1.62,0.07,3.51,1.7,4.2h0c0.4,0.17,0.82,0.25,1.25,0.25h1.83   c0.46,0,0.92,0.01,1.37,0.04V7.42c-0.45,0.03-0.91,0.04-1.37,0.04H8.06C8.06,7.47,8.06,7.47,8.06,7.47z"
      clipRule="evenodd"
    />
  </svg>,
  'Marketing'
);

export default Marketing;
