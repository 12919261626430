import React, { FC, ReactNode, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, Collapse, Stack } from '@mui/material';
import { SideNavItem } from './side-nav-item';
import filter from 'lodash/filter';
import startsWith from 'lodash/startsWith';
import MinusIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import { paths } from 'src/paths';

interface Item {
  disabled?: boolean;
  icon?: ReactNode;
  items?: Item[];
  childRoutes?: string[];
  label?: ReactNode;
  path?: string;
  title: string;
}

const renderItems = ({
  depth = 0,
  items,
  pathname,
}: {
  depth?: number;
  items: Item[];
  pathname?: string | null;
}): JSX.Element[] =>
  items.reduce(
    (acc: JSX.Element[], item) =>
      reduceChildRoutes({
        acc,
        depth,
        item,
        pathname,
      }),
    []
  );

const reduceChildRoutes = ({
  acc,
  depth,
  item,
  pathname,
}: {
  acc: JSX.Element[];
  depth: number;
  item: Item;
  pathname?: string | null;
}): Array<JSX.Element> => {
  const checkPath = !!(item.path && pathname);
  let partialMatch = checkPath ? startsWith(pathname, item.path!) : false;
  if (!partialMatch && item.childRoutes?.length > 0) {
    partialMatch =
      filter(item.childRoutes, (childRoute) => startsWith(pathname, childRoute)).length > 0;
  }

  if (item.items) {
    acc.push(
      <SideNavItem
        active={partialMatch}
        depth={depth}
        disabled={item.disabled}
        icon={item.icon}
        key={item.title}
        label={item.label}
        open={partialMatch}
        path={item.path}
        title={item.title}
      >
        <Stack
          component="ul"
          spacing={0.5}
          sx={{
            listStyle: 'none',
            m: 0,
            p: 0,
          }}
        >
          {renderItems({
            depth: depth + 1,
            items: item.items,
            pathname,
          })}
        </Stack>
      </SideNavItem>
    );
  } else {
    acc.push(
      <SideNavItem
        active={partialMatch}
        depth={depth}
        disabled={item.disabled}
        icon={item.icon}
        key={item.title}
        label={item.label}
        path={item.path}
        title={item.title}
      />
    );
  }

  return acc;
};

interface SideNavSectionProps {
  items?: Item[];
  pathname?: string | null;
  compact?: boolean;
  subheader?: string;
  sectionPath?: string;
  activeSection?: string;
  onToggleSection: (activeSection: string) => void;
}

export const SideNavSection: FC<SideNavSectionProps> = (props) => {
  const {
    items = [],
    pathname,
    compact = false,
    subheader = '',
    sectionPath = paths.operations,
    activeSection,
    onToggleSection,
    ...other
  } = props;
  const icon = activeSection !== sectionPath ? <AddIcon /> : <MinusIcon />;

  const handleToggleSection = useCallback(
    (activeSection: string) => {
      onToggleSection?.(activeSection);
    },
    [onToggleSection]
  );

  if (subheader) {
    return (
      <React.Fragment>
        <Button
          endIcon={icon}
          sx={{
            justifyContent: 'flex-start',
            textAlign: 'left',
            width: '100%',
            color: 'var(--nav-section-title-color)',
            fontSize: 12,
            fontWeight: 700,
            lineHeight: 1.66,
            textTransform: 'uppercase',
          }}
          variant="text"
          onClick={() => handleToggleSection(sectionPath)}
        >
          {subheader}
        </Button>
        <Collapse in={activeSection === sectionPath}>
          <Stack
            component="ul"
            spacing={0.5}
            sx={{
              listStyle: 'none',
              m: 0,
              p: 0,
            }}
            {...other}
          >
            {renderItems({ items, pathname })}
          </Stack>
        </Collapse>
      </React.Fragment>
    );
  }

  return (
    <Stack
      component="ul"
      spacing={0.5}
      sx={{
        listStyle: 'none',
        m: 0,
        p: 0,
      }}
      {...other}
    >
      {renderItems({ items, pathname })}
    </Stack>
  );
};

SideNavSection.propTypes = {
  items: PropTypes.array,
  pathname: PropTypes.string,
  subheader: PropTypes.string,
};
