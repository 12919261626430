import type { FC, ReactNode } from 'react';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useAuth } from 'src/hooks/use-auth';
import findIndex from 'lodash/findIndex';
import { paths } from 'src/paths';

interface AuthGuardProps {
  children: ReactNode;
  permission?: string | null;
  subscriptions?: string[] | null;
}

export const AuthGuard: FC<AuthGuardProps> = (props) => {
  const { children, permission = null, subscriptions } = props;
  const { isAuthenticated, permissions, subscriptionPlan } = useAuth();
  const router = useRouter();
  const [checked, setChecked] = useState(false);

  useEffect(
    () => {
      if (!router.isReady) {
        return;
      }

      if (!isAuthenticated) {
        router.push(paths.authentication.login);
      } else {
        setChecked(true);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [router.isReady]
  );

  useEffect(
    () => {
      if (checked && !isAuthenticated) {
        router.push(paths.authentication.login);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [checked, isAuthenticated]
  );

  if (!checked || !isAuthenticated) {
    return null;
  }

  if (permission && findIndex(permissions, (p) => p.name === permission) === -1) {
    router.push(paths['401']);
    return null;
  }

  if (subscriptions && findIndex(subscriptions, (s) => s === subscriptionPlan) === -1) {
    router.push(paths['401']);
    return null;
  }

  // If got here, it means that the redirect did not occur, and that tells us that the user is
  // authenticated / authorized.

  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
  permission: PropTypes.string,
};
