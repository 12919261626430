import { createSvgIcon } from '@mui/material/utils';

const Quote = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
  >
    <path
      fillRule="evenodd"
      d="M15.73,2.14c-2.43,0-4.51,1.46-5.43,3.55H3.28c-0.52,0-0.94,0.42-0.94,0.94v14.29c0,0.52,0.42,0.94,0.94,0.94h14.29     c0.52,0,0.94-0.42,0.94-0.94V13.3c1.87-1,3.14-2.97,3.14-5.23C21.66,4.79,19,2.14,15.73,2.14z M17.02,20.36H3.84V7.19h6.03     C9.83,7.48,9.8,7.77,9.8,8.07c0,3.28,2.66,5.93,5.93,5.93c0.44,0,0.87-0.05,1.29-0.14V20.36z M18.52,11.49     c-0.44,0.36-0.94,0.63-1.5,0.8c-0.41,0.13-0.84,0.21-1.29,0.21c-2.44,0-4.43-1.99-4.43-4.43c0-0.3,0.03-0.6,0.09-0.88     c0.11-0.54,0.33-1.05,0.62-1.5c0.79-1.23,2.16-2.05,3.72-2.05c2.44,0,4.43,1.99,4.43,4.43C20.16,9.45,19.51,10.67,18.52,11.49z"
    />
    <path
      d="M18.15,5.72c-0.17,0-0.32,0.07-0.44,0.18l-3.01,3.01l-0.94-0.94c-0.12-0.12-0.27-0.18-0.44-0.18     c-0.17,0-0.32,0.07-0.44,0.18c-0.24,0.24-0.24,0.64,0,0.88l1.38,1.38c0.12,0.12,0.27,0.18,0.44,0.18c0.17,0,0.32-0.06,0.44-0.18     l3.45-3.45c0.24-0.24,0.24-0.64,0-0.88C18.48,5.79,18.32,5.72,18.15,5.72z"
      clipRule="evenodd"
    />
  </svg>,
  'Quote'
);

export default Quote;
