import { createSvgIcon } from '@mui/material/utils';

const PlanAndPayment = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
  >
    <path
      fillRule="evenodd"
      d="M22.21,12.54c-0.65-4.59-4.42-8.25-9.03-8.76c-0.4-0.04-0.79-0.07-1.18-0.07c-5.69,0-10.31,4.62-10.31,10.31   c0,2.09,0.63,4.03,1.7,5.65c0.28,0.43,0.76,0.7,1.27,0.7h14.68c0.52,0,0.99-0.27,1.27-0.7C21.93,17.67,22.58,15.19,22.21,12.54z    M19.29,18.78H4.71c-0.93-1.42-1.42-3.06-1.42-4.76c0-4.8,3.91-8.71,8.71-8.71c0.33,0,0.67,0.02,1,0.06   c3.87,0.43,7.08,3.54,7.62,7.39C20.92,14.91,20.46,16.99,19.29,18.78z"
    />
    <path
      d="M11.82,13.36c-0.14,0.02-0.28,0.04-0.41,0.09l-3.49-4.1C7.63,9.01,7.12,8.97,6.79,9.26c-0.34,0.29-0.38,0.79-0.09,1.13   l3.5,4.11c-0.18,0.35-0.26,0.74-0.21,1.16c0.12,1.14,1.15,1.96,2.28,1.84c1.14-0.12,1.96-1.15,1.84-2.28   C13.98,14.06,12.95,13.24,11.82,13.36z M12.09,15.89c-0.26,0.03-0.49-0.16-0.52-0.42c-0.03-0.26,0.16-0.49,0.42-0.52   c0.26-0.03,0.49,0.16,0.52,0.42S12.35,15.87,12.09,15.89z"
      clipRule="evenodd"
    />
    <path
      d="M18.53,13.56c-0.41,0-0.75,0.34-0.75,0.75c0,0.41,0.34,0.75,0.75,0.75c0.41,0,0.75-0.34,0.75-0.75   C19.28,13.9,18.94,13.56,18.53,13.56z"
      clipRule="evenodd"
    />
    <path
      d="M5.47,13.56c-0.41,0-0.75,0.34-0.75,0.75c0,0.41,0.34,0.75,0.75,0.75c0.41,0,0.75-0.34,0.75-0.75   C6.22,13.9,5.88,13.56,5.47,13.56z"
      clipRule="evenodd"
    />
    <path
      d="M16.75,9.07C16.33,9.07,16,9.4,16,9.81c0,0.41,0.34,0.75,0.75,0.75c0.41,0,0.75-0.34,0.75-0.75   C17.5,9.4,17.16,9.07,16.75,9.07z"
      clipRule="evenodd"
    />
    <path
      d="M12,7.06c-0.41,0-0.75,0.34-0.75,0.75c0,0.41,0.34,0.75,0.75,0.75c0.41,0,0.75-0.34,0.75-0.75   C12.75,7.4,12.41,7.06,12,7.06z"
      clipRule="evenodd"
    />
  </svg>,
  'PlanAndPayment'
);

export default PlanAndPayment;
