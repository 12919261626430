import { createSvgIcon } from '@mui/material/utils';

const Dashboard = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
  >
    <path
      fillRule="evenodd"
      d="M14.89,16.49c-0.32,0-0.61-0.18-0.76-0.48l-2.98-6.5l-1.31,2.76c-0.13,0.29-0.44,0.49-0.77,0.49    H6.74c-0.23,0-0.45-0.09-0.61-0.26c-0.16-0.16-0.24-0.38-0.23-0.59c0-0.46,0.38-0.84,0.85-0.84h1.79l1.86-3.94    c0.11-0.25,0.35-0.44,0.64-0.48c0,0,0.01,0,0.01,0c0.55,0,0.79,0.26,0.89,0.47l2.94,6.43l0.86-1.98c0.13-0.31,0.44-0.51,0.78-0.51    h3.96c0.24,0.01,0.45,0.1,0.6,0.26c0.16,0.16,0.25,0.37,0.25,0.6c0,0.47-0.38,0.85-0.85,0.85h-3.41l-1.4,3.21    c-0.09,0.19-0.23,0.33-0.4,0.42C15.13,16.46,15.02,16.49,14.89,16.49z"
    />
    <path
      d="M4.52,21.38c-0.91,0-1.65-0.73-1.65-1.62V4.25c-0.01-0.44,0.3-0.8,0.74-0.86    c0.34,0.01,0.55,0.09,0.72,0.26c0.15,0.17,0.23,0.38,0.23,0.6v15.5l15.91-0.08c0.24,0.01,0.44,0.1,0.6,0.25    c0.18,0.19,0.26,0.41,0.24,0.64c0,0.2-0.09,0.41-0.24,0.57c-0.16,0.16-0.38,0.25-0.61,0.25H4.52z"
      clipRule="evenodd"
    />
  </svg>,
  'Dashboard'
);

export default Dashboard;
