import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Stack } from '@mui/material';
import { SideNavItemCompact as SideNavItem } from './side-nav-item-compact';

interface Item {
  disabled?: boolean;
  icon?: ReactNode;
  items?: Item[];
  childRoutes?: string[];
  label?: ReactNode;
  path?: string;
  title: string;
}

interface SideNavCompactSectionProps {
  items?: Item[];
  pathname?: string | null;
  subheader?: string;
}

export const SideNavCompactSection: FC<SideNavCompactSectionProps> = (props) => {
  const { items = [], pathname } = props;
  return (
    <Stack
      component="ul"
      sx={{
        listStyle: 'none',
        m: 0,
        p: 0,
      }}
    >
      {items.map((item) => {
        const checkPath = !!(item.path && pathname);
        let partialMatch = checkPath ? pathname.includes(item.path!) : false;
        if (!partialMatch && item.childRoutes?.length > 0) {
          partialMatch = item.childRoutes.includes(item.path!);
        }
        if (item.items) {
          // Branch

          return (
            <SideNavItem
              active={partialMatch}
              disabled={item.disabled}
              icon={item.icon}
              items={item.items}
              key={item.title}
              label={item.label}
              path={item.path}
              title={item.title}
            />
          );
        }

        // Leaf

        return (
          <SideNavItem
            active={partialMatch}
            disabled={item.disabled}
            icon={item.icon}
            key={item.title}
            label={item.label}
            path={item.path}
            title={item.title}
          />
        );
      })}
    </Stack>
  );
};

SideNavCompactSection.propTypes = {
  items: PropTypes.array,
  pathname: PropTypes.string,
  subheader: PropTypes.string,
};
