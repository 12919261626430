import { createSvgIcon } from '@mui/material/utils';

const Integrations = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
  >
    <path
      fillRule="evenodd"
      d="M15.68,9.47c0.47,0.27,0.99,0.42,1.52,0.42c0.79,0,1.58-0.3,2.18-0.9c1.21-1.21,1.21-3.16,0-4.37   c-0.6-0.6-1.39-0.9-2.18-0.9s-1.58,0.3-2.18,0.9c-1.04,1.04-1.18,2.63-0.43,3.82l-6.15,6.15c-0.5-0.31-1.07-0.48-1.64-0.48   c-0.79,0-1.58,0.3-2.18,0.9c-1.21,1.21-1.21,3.16,0,4.37c0.6,0.6,1.39,0.9,2.18,0.9s1.58-0.3,2.18-0.9c1-1,1.16-2.53,0.49-3.7   L15.68,9.47z M16.08,5.68c0.3-0.3,0.7-0.46,1.12-0.46s0.82,0.17,1.12,0.46c0.62,0.62,0.62,1.63,0,2.24c-0.3,0.3-0.7,0.46-1.12,0.46   s-0.82-0.17-1.12-0.46C15.46,7.3,15.46,6.3,16.08,5.68z M7.92,18.32c-0.3,0.3-0.7,0.46-1.12,0.46s-0.82-0.17-1.12-0.46   c-0.62-0.62-0.62-1.63,0-2.24c0.3-0.3,0.7-0.46,1.12-0.46s0.82,0.17,1.12,0.46C8.54,16.7,8.54,17.7,7.92,18.32z"
    />
    <path
      d="M19.35,12.43c-0.41,0-0.75,0.34-0.75,0.75v5.42h-5.42c-0.41,0-0.75,0.34-0.75,0.75s0.34,0.75,0.75,0.75h6.17   c0.41,0,0.75-0.34,0.75-0.75v-6.17C20.1,12.77,19.77,12.43,19.35,12.43z"
      clipRule="evenodd"
    />
    <path
      d="M4.65,11.69c0.41,0,0.75-0.34,0.75-0.75V5.4h5.55c0.41,0,0.75-0.34,0.75-0.75S11.36,3.9,10.94,3.9h-6.3   C4.23,3.9,3.9,4.23,3.9,4.65v6.3C3.9,11.36,4.23,11.69,4.65,11.69z"
      clipRule="evenodd"
    />
  </svg>,
  'Integrations'
);

export default Integrations;
