import type { FC } from 'react';
import Settings03Icon from '@untitled-ui/icons-react/build/esm/Settings03';
import { Avatar, Box, ButtonBase, Tooltip } from '@mui/material';
import { useSettings } from 'src/hooks/use-settings';
import { tokens } from 'src/locales/tokens';
import { useTranslation } from 'react-i18next';

export const SettingsButton: FC = () => {
  const { handleDrawerOpen } = useSettings();
  const { t } = useTranslation();

  return (
    <Tooltip title={t(tokens.common.settings)}>
      <Box
        component={ButtonBase}
        onClick={handleDrawerOpen}
        sx={{
          height: 40,
          width: 40,
          borderRadius: '50%',
        }}
      >
        <Avatar
          sx={{
            height: 30,
            width: 30,
          }}
        >
          <Settings03Icon />
        </Avatar>
      </Box>
    </Tooltip>
  );
};
