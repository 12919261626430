import { createSvgIcon } from '@mui/material/utils';

const Team = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
  >
    <path
      fillRule="evenodd"
      d="M16.07,13.13c1.81-1.15,3.01-3.16,3.01-5.45c0-3.56-2.9-6.46-6.46-6.46c-2.64,0-4.91,1.59-5.91,3.87H3.65  c-0.44,0-0.8,0.36-0.8,0.8s0.36,0.8,0.8,0.8h2.59C6.19,7.01,6.16,7.34,6.16,7.68c0,2.29,1.2,4.31,3.01,5.45  c-3.24,1.35-5.52,4.56-5.52,8.28c0,0.47,0.38,0.85,0.85,0.85c0.47,0,0.85-0.38,0.85-0.85c0-4.01,3.26-7.27,7.27-7.27  s7.27,3.26,7.27,7.27c0,0.47,0.38,0.85,0.85,0.85c0.47,0,0.85-0.38,0.85-0.85C21.59,17.69,19.31,14.49,16.07,13.13z M12.62,2.92  c1.67,0,3.13,0.87,3.98,2.17H8.64C9.49,3.79,10.95,2.92,12.62,2.92z M12.62,12.44c-2.63,0-4.76-2.14-4.76-4.76  c0-0.34,0.04-0.67,0.11-0.99h9.31c0.07,0.32,0.11,0.65,0.11,0.99C17.38,10.3,15.24,12.44,12.62,12.44z"
      clipRule="evenodd"
    />
  </svg>,
  'Team'
);

export default Team;
