import type { FC } from 'react';
import PropTypes from 'prop-types';
import { ButtonBase, useTheme } from '@mui/material';
import { alpha } from '@mui/material/styles';

export const SidebarSwitcher: FC<{ sidebarOpen: boolean; onClick: () => void }> = ({
  sidebarOpen,
  onClick,
}) => {
  const theme = useTheme();

  return (
    <ButtonBase
      sx={{
        position: 'absolute',
        top: '150px',
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        zIndex: theme.zIndex.drawer + 5,
        px: 1,
        color: alpha(theme.palette.grey[500], 0.8),
        transition: '0.3s',
        '&:hover': {
          color: alpha(theme.palette.grey[800], 0.9),
          backgroundColor: alpha(theme.palette.grey[800], 0.03),
          '&:before': {
            transform: sidebarOpen ? 'rotate(114deg)' : 'rotate(66deg)',
            backgroundColor: theme.palette.primary.main,
          },
          '&:after': {
            transform: sidebarOpen ? 'rotate(66deg)' : 'rotate(114deg)',
            backgroundColor: theme.palette.primary.main,
          },
        },
        '&:after, &:before': {
          content: '" "',
          width: '16px',
          height: '3px',
          position: 'absolute',
          backgroundColor: '#bfbfbf',
          bottom: 'calc(50% - 13px)',
          left: 0,
          right: 0,
          margin: '0 auto',
          transition: '0.2s',
          top: '50%',
          transform: 'translateY(-50%) rotate(90deg)',
          borderRadius: '2px',
        },
        '&:before': {
          top: 'calc(50% - 13px)',
        },
      }}
      onClick={onClick}
    />
  );
};

SidebarSwitcher.propTypes = {
  sidebarOpen: PropTypes.bool,
  onClick: PropTypes.func,
};
