import { createSvgIcon } from '@mui/material/utils';

const Lab = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
  >
    <path
      fillRule="evenodd"
      d="M16.54,21.96c-0.1,0-0.2,0-0.3-0.01l-8.49,0c-0.08,0.01-0.18,0.01-0.29,0.01c-0.59,0-1.16-0.13-1.68-0.38   c-0.62-0.29-1.12-0.72-1.49-1.26c-0.33-0.61-0.51-1.24-0.54-1.9c-0.03-0.67,0.11-1.31,0.43-1.9l3.96-6.67L8.13,4.15   c0-0.28-0.22-0.5-0.5-0.5c-0.44,0-0.8-0.36-0.8-0.8c0-0.44,0.36-0.8,0.8-0.8c1.16,0,2.11,0.94,2.11,2.11l0,4.98h4.53l0-4.98   c0-1.16,0.94-2.11,2.11-2.11c0.44,0,0.8,0.36,0.8,0.8c0,0.44-0.36,0.8-0.8,0.8c-0.28,0-0.5,0.22-0.5,0.5l-0.01,5.69l3.96,6.66   c0.33,0.6,0.47,1.24,0.44,1.91c-0.03,0.66-0.21,1.29-0.53,1.88c-0.38,0.56-0.88,0.98-1.5,1.28C17.7,21.83,17.13,21.96,16.54,21.96z    M5.69,17.3c-0.16,0.34-0.24,0.72-0.24,1.1s0.08,0.76,0.25,1.13c0.19,0.25,0.49,0.49,0.86,0.65c0.28,0.12,0.56,0.19,0.85,0.19h9.18   c0.29,0,0.58-0.06,0.85-0.19c0.37-0.16,0.67-0.4,0.88-0.68c0.15-0.33,0.23-0.71,0.23-1.09s-0.08-0.76-0.25-1.12l-3.76-6.45   L12,10.81l-2.54,0.02L5.69,17.3z"
      clipRule="evenodd"
    />
  </svg>,
  'Lab'
);

export default Lab;
