import type { FC } from 'react';
import { useCallback, useRef, useState } from 'react';
import User01Icon from '@untitled-ui/icons-react/build/esm/User01';
import { Avatar, Box, ButtonBase, Tooltip } from '@mui/material';
import { AccountPopover } from './account-popover';
import { tokens } from 'src/locales/tokens';
import { useTranslation } from 'react-i18next';

export const AccountButton: FC = () => {
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const [openPopover, setOpenPopover] = useState<boolean>(false);
  const { t } = useTranslation();

  const handlePopoverOpen = useCallback((): void => {
    setOpenPopover(true);
  }, []);

  const handlePopoverClose = useCallback((): void => {
    setOpenPopover(false);
  }, []);

  return (
    <>
      <Tooltip title={t(tokens.common.account)}>
        <Box
          component={ButtonBase}
          onClick={handlePopoverOpen}
          ref={anchorRef}
          sx={{
            height: 40,
            width: 40,
            borderRadius: '50%',
          }}
        >
          <Avatar
            sx={{
              height: 30,
              width: 30,
            }}
          >
            <User01Icon />
          </Avatar>
        </Box>
      </Tooltip>
      <AccountPopover
        anchorEl={anchorRef.current}
        onClose={handlePopoverClose}
        open={openPopover}
      />
    </>
  );
};
