/**
 * uppercase first letter of string
 * @param string string
 */
export function ucFirst(string) {
  return !string ? '' : string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * check a string on emptiness
 * @param str string
 */
export function isEmpty(str) {
  return !str || str.length === 0;
}

/**
 * check a string on blankness
 * @param str string
 */
export function isBlank(str) {
  return !str || /^\s*$/.test(str);
}

/**
 * Convert string to studly case,
 * splitting on non alpha boundaries
 *
 * @param {*string} str String to act on
 * @param {*boolean} first UC first letter (default true)
 * @param {*string} separator (default '')
 */
export function studly(str, first, separator = '') {
  if (!str) return '';
  first = first !== false;
  return str
    .split(/[-_]/)
    .map((el, idx) => (idx === 0 && !first ? el : ucFirst(el)))
    .join(separator);
}

/**
 * Convert string to kebab case
 * @param {*string} str
 */
export function kebab(str) {
  return str
    .replace(/([a-z])([A-Z])/g, '$1-$2')
    .replace(/\s+/g, '-')
    .toLowerCase();
}

/**
 * Convert studly string to spaced string
 * @param {string} str
 */
export function spaced(str) {
  return str.match(/[A-Z][a-z]+|[0-9]+/g).join(' ');
}

/**
 * Converts any string to a color
 * https://stackoverflow.com/a/16348977
 * @param str string
 * @returns color in hex format
 */
export function stringToColour(str): string {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    // eslint-disable-next-line no-bitwise
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let colour = '#';
  for (let i = 0; i < 3; i++) {
    // eslint-disable-next-line no-bitwise
    const value = (hash >> (i * 8)) & 0xff;
    colour += `00${value.toString(16)}`.substr(-2);
  }
  return colour;
}

/**
 * Converts a string to an acronym
 * @param {string} str
 */
export function getAcronym(str: string): string {
  return str
    .split(/\s/)
    .slice(0, 2)
    .reduce((response, word) => (response += word.slice(0, 1)), '')
    .toUpperCase();
}
