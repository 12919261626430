import { createSvgIcon } from '@mui/material/utils';

const TestParameters = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
  >
    <path
      fillRule="evenodd"
      d="M3.66,9.75c0.41,0,0.75-0.34,0.75-0.75V7.72c0-0.41-0.34-0.75-0.75-0.75S2.91,7.31,2.91,7.72V9   C2.91,9.41,3.24,9.75,3.66,9.75z"
    />
    <path
      d="M7.66,9.75c0.41,0,0.75-0.34,0.75-0.75V7.72c0-0.41-0.34-0.75-0.75-0.75S6.91,7.31,6.91,7.72V9   C6.91,9.41,7.24,9.75,7.66,9.75z"
      clipRule="evenodd"
    />
    <path
      d="M11.66,9.75c0.41,0,0.75-0.34,0.75-0.75V7.72c0-0.41-0.33-0.75-0.75-0.75c-0.41,0-0.75,0.34-0.75,0.75V9   C10.91,9.41,11.24,9.75,11.66,9.75z"
      clipRule="evenodd"
    />
    <path
      d="M15.66,9.75c0.41,0,0.75-0.34,0.75-0.75V7.72c0-0.41-0.34-0.75-0.75-0.75s-0.75,0.34-0.75,0.75V9   C14.91,9.41,15.24,9.75,15.66,9.75z"
      clipRule="evenodd"
    />
    <path
      d="M19.66,6.97c-0.41,0-0.75,0.34-0.75,0.75V9c0,0.41,0.34,0.75,0.75,0.75S20.41,9.41,20.41,9V7.72   C20.41,7.31,20.07,6.97,19.66,6.97z"
      clipRule="evenodd"
    />
    <path
      d="M19.66,13.52h-6.02c-0.3-0.81-1.08-1.38-1.99-1.38c-0.59,0-1.13,0.24-1.52,0.63H4.41c-0.83,0-1.5,0.67-1.5,1.5   s0.67,1.5,1.5,1.5h5.72c0.39,0.39,0.92,0.63,1.52,0.63c0.91,0,1.69-0.58,1.99-1.38h6.02c0.41,0,0.75-0.34,0.75-0.75   S20.07,13.52,19.66,13.52z M12.08,14.73c-0.11,0.11-0.27,0.18-0.44,0.18c-0.35,0-0.63-0.28-0.63-0.63c0-0.35,0.28-0.63,0.63-0.63   c0.17,0,0.32,0.07,0.44,0.18c0.12,0.12,0.2,0.28,0.2,0.46C12.28,14.45,12.2,14.61,12.08,14.73z"
      clipRule="evenodd"
    />
  </svg>,
  'TestParameters'
);

export default TestParameters;
